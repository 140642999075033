// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'redeem_code',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'redeemed_at',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'remark',
    label: '',
    Object: 'value',
    width: '300'
  }]
}
